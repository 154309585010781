import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { HukAutocomplete, HukButton, HukInputField } from "huk-react-bindings";

import {
  updateCPID,
  updateDynamicValue,
  updateProducerCode,
  updateDynamicValidation,
} from "redux/actions/inputValueActions";
import { getCPIDURL } from "shared/apiEndpoints";
import { callGetSearchResultsAPI } from "shared/helper";
import {
  requiredFieldErrorMessage,
  inputFieldClickdAnalytics,
  pageTag,
} from "shared/messages";
import {
  trackCustomEvent,
  trackCustomEventError,
} from "tealium/trackCustomEvent";
import { axiosPostInstance } from "shared/axiosInstance";
import { updateRiskStateValidation } from "redux/actions/riskStateActions";

import TextInput from "./DynamicInput/TextInput";
import NumberInput from "./DynamicInput/NumberInput";

import {
  updateBusinessName,
  updateDBA,
  updateStreet,
  updateCity,
  updateState,
  updateZip,
} from "redux/actions/businessSearchActions";

const SpectrumSearch = ({ activeTabID }) => {
  const dispatch = useDispatch();
  const previousCPIDRef = useRef(null);
  const [currentCPIDResponseValid, setCurrentCPIDResponseValid] = useState();
  const [refreshCount, setRefreshCount] = useState(0);
  const userType = sessionStorage.getItem("userType");
  const defaultCPIDValidationMsg = "Enter 4 characters";
  const [CPIDValidationMsg, setCPIDValidationMsg] = useState("");
  const [showCPIDValidation, setShowCPIDValidation] = useState(false);
  const defaultProducerCdValidationMsg = "Enter an 8-digit Producer code";
  const invalidCPIDMsg = "Invalid CPID - please enter a different CPID";
  const [producerCdValidationMsg, setProducerCdValidationMsg] = useState("");
  const [showProducerCodeValidation, setShowProducerCodeValidation] =
    useState(false);
  const { riskState } = useSelector((state) => state.riskStateReducer);
  const { workbinProducerCodes } = useSelector(
    (state) => state.workbinProducerCodesReducer
  );
  const { dynamicValue, producerCode, cpid } = useSelector(
    (state) => state.inputValueReducer
  );
  const { selectedOption, searchType, name } = useSelector(
    (state) => state.searchByReducer
  );
  const { helperText, range, validationMessage } = useSelector(
    (state) => state.searchByReducer.dynamicField
  );
  const dynamicInputStringValue = dynamicValue.trim().toString();
  const prevActiveTabID = useRef(activeTabID);
  const prevSelectedOption = useRef(selectedOption);

  /* istanbul ignore next */
  useEffect(() => {
    // reset all business search inputs so when leaving component and returning they are empty
    dispatch(updateDBA(""));
    dispatch(updateZip(""));
    dispatch(updateCity(""));
    dispatch(updateState(""));
    dispatch(updateStreet(""));
    dispatch(updateBusinessName(""));
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    /*
      Reset the input values ONLY when the tab changes
      If condition is used to allow the input values to remain when returning from Class Details Page via link
    */
    if (activeTabID !== prevActiveTabID.current) {
      dispatch(updateCPID(""));
      dispatch(updateDynamicValue(""));
      dispatch(updateProducerCode(""));
      sessionStorage.removeItem("sentinelInd");
      setRefreshCount((refreshCount % 100) + 1); // used to reset validation by using HUK refresh attribute
      setShowCPIDValidation(false);
      setShowProducerCodeValidation(false);
    }

    if (selectedOption !== prevSelectedOption.current) {
      dispatch(updateDynamicValue(""));
    }

    // Update the previous values
    prevActiveTabID.current = activeTabID;
    prevSelectedOption.current = selectedOption;
  }, [activeTabID, selectedOption]);

  /**
   * Determines whether or not producer code field is valid
   * @param {string} producerCode producer code from the input
   * @return {boolean} Determines whether producer code input field is valid or not
   */
  const checkProducerCodeInvalid = () => {
    if (producerCode && producerCode.length < 8) {
      return true;
    } else if (
      userType === "external" &&
      producerCode.length === 8 &&
      !workbinProducerCodes.includes(producerCode)
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Determines whether or not CPID field is valid ONLY for the UI (this does NOT include if CPID is valid from service call)
   * @return {boolean} Determines whether CPID input field is valid or not
   */
  const checkCPIDInvalid = () => {
    if (!cpid) {
      return false;
    } else {
      return !/^[A-Za-z]\d{3}$/.test(cpid);
    }
  };

  /**
   * check if all fields are valid
   * @return {boolean} - true if all fields are valid, false otherwise
   */
  const checkAllFieldsValid = () => {
    let areAllFieldsValid = true;
    const dynamicInputStringValue = dynamicValue.trim().toString();

    if (!riskState) {
      dispatch(updateRiskStateValidation(true));
      areAllFieldsValid = false;
    }

    if (!dynamicInputStringValue || dynamicInputStringValue.length < range[0]) {
      dispatch(updateDynamicValidation(true));
      areAllFieldsValid = false;
      /**
        This below else/dispatch is causing the spinner to load AND show 'Search' text next to it
        Work around is to dynamically use state variable to set button text (see below at API call function)
        if you comment out below code the spinner and button works as it should
       */
    } else {
      dispatch(updateDynamicValidation(false));
    }

    if (checkProducerCodeInvalid() || checkCPIDInvalid()) {
      areAllFieldsValid = false;
    }
    return areAllFieldsValid;
  };

  /**
   * On click of search btn if any field is invalid find that invalid field and send analytics info for that field
   */
  const handleValidationErrorAnalyticsOnSubmit = () => {
    if (!riskState) {
      trackCustomEventError(
        "Risk State",
        `${requiredFieldErrorMessage}`,
        "true"
      );
    }

    if (!dynamicInputStringValue || dynamicInputStringValue.length < range[0]) {
      trackCustomEventError(
        `${selectedOption}`,
        `${validationMessage}`,
        "true"
      );
    }

    if (checkProducerCodeInvalid()) {
      trackCustomEventError(
        "Producer Code",
        `${producerCdValidationMsg}`,
        "true"
      );
    }

    if (checkCPIDInvalid()) {
      trackCustomEventError("CPID", `${CPIDValidationMsg}`, "true");
    }
  };

  /**
   * sets the validation message for producer code via useState when input is blurred or search btn is clicked
   * @return {void}
   */
  const handleProducerCodeValidationMsg = () => {
    // Due to asynchronous nature of useState can't wait for state variable to be set before sending to analytics so need local variable
    let producerCodeMsg;

    if (userType === "internal") {
      producerCodeMsg = defaultProducerCdValidationMsg;
    } else {
      if (
        producerCode.length === 8 &&
        !workbinProducerCodes.includes(producerCode)
      ) {
        producerCodeMsg =
          "Invalid Producer Code - please select a different code";
      } else {
        producerCodeMsg = defaultProducerCdValidationMsg;
      }
    }
    setProducerCdValidationMsg(producerCodeMsg);

    if (!producerCode || producerCode.length === 0) {
      setShowProducerCodeValidation(false);
      return;
    } else {
      setShowProducerCodeValidation(checkProducerCodeInvalid());
    }

    // send appropriate analytics on blur
    if (checkProducerCodeInvalid()) {
      trackCustomEventError(
        "Producer Code",
        `${producerCdValidationMsg}`,
        "true"
      );
    } else {
      trackCustomEvent(
        `${pageTag}`,
        `${inputFieldClickdAnalytics}`,
        `Producer Code`,
        `${producerCode}`,
        "true"
      );
    }
  };

  /**
   * sets the validation message for CPID code via useState when input is blurred or search btn is clicked
   * @return {void}
   */
  const handleCPIDValidationMsg = () => {
    // Due to asynchronous nature of useState can't wait for state variable to be set before sending to analytics so need local variable
    let validationMsg;

    // determine what validation message to show
    if (cpid && cpid.length < 4) {
      validationMsg = defaultCPIDValidationMsg;
    } else {
      validationMsg = invalidCPIDMsg;
    }
    setCPIDValidationMsg(validationMsg);

    // determine when to show the validation message
    if (!cpid || cpid.length === 0) {
      setShowCPIDValidation(false);
    } else if (!currentCPIDResponseValid && cpid === previousCPIDRef.current) {
      setShowCPIDValidation(true);
      trackCustomEventError("CPID", `${validationMsg}`, "true");
    } else {
      setShowCPIDValidation(checkCPIDInvalid());
    }

    // send appropriate analytics on blur
    if (checkCPIDInvalid()) {
      trackCustomEventError("CPID", `${validationMsg}`, "true");
    } else {
      trackCustomEvent(
        `${pageTag}`,
        `${inputFieldClickdAnalytics}`,
        `CPID`,
        `${cpid}`,
        "true"
      );
    }
  };

  /**
   * Handler for search button click and also sets the value for the loading indicator
   * @return {void}
   */
  const handleSubmit = async (e) => {
    /* istanbul ignore next */
    e.preventDefault();

    if (!checkAllFieldsValid()) {
      handleValidationErrorAnalyticsOnSubmit();
      return;
    }
    if (cpid) {
      // if CPID value has changed, call service to see if CPID is valid
      if (cpid !== previousCPIDRef.current) {
        previousCPIDRef.current = cpid;
        const sentinelInd = await axiosPostInstance()
          .post(getCPIDURL, { CPID: cpid })
          .then((res) => {
            const sentinelIndResponse = res.data?.data[0]?.sentinelIndicator;
            sessionStorage.setItem("sentinelInd", sentinelIndResponse);
            return sentinelIndResponse;
          })
          .catch((error) => {
            console.error("Error in CPID API call: ", error);
          });

        // if CPID response returns null data/invalid CPID code then throw up that validation message and do not call getSearchResults
        if (!sentinelInd) {
          setCPIDValidationMsg(invalidCPIDMsg);
          setCurrentCPIDResponseValid(false);
          setShowCPIDValidation(true);
          trackCustomEventError("CPID", `${CPIDValidationMsg}`, "true");
          return;
        } else {
          setCurrentCPIDResponseValid(true);
        }
        // if CPID has not changed but that CPID is invalid do NOT call the service again
      } else if (
        cpid === previousCPIDRef.current &&
        !currentCPIDResponseValid
      ) {
        setCurrentCPIDResponseValid(false);
        setShowCPIDValidation(true);
        trackCustomEventError("CPID", `${CPIDValidationMsg}`, "true");
        return;
      }
    } else {
      sessionStorage.setItem("sentinelInd", "Y");
      setShowCPIDValidation(false);
    }

    const getSearchResultsPayload = {
      segmentCd: "SC",
      stateCd: riskState,
      returnAssocClass: "Y",
      searchType: searchType,
      sentinelIndicator: sessionStorage.getItem("sentinelInd"),
      searchId: dynamicValue,
      producerCode: producerCode,
      lobcd: name !== "ISO GL code" ? "SPEC" : "GL",
      agentViewIndicator: userType === "internal" ? "N" : "Y",
    };

    callGetSearchResultsAPI(dispatch, getSearchResultsPayload);

    setShowCPIDValidation(false);
    setShowProducerCodeValidation(false);

    trackCustomEvent(
      `${pageTag}`,
      "Search Button Click",
      `SearchBy_${selectedOption}`,
      `RiskState_${riskState} | ${selectedOption}_${dynamicValue} | ProducerCode_${producerCode} | CPID_${cpid}`,
      "true"
    );
  };

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleCPIDValidationMsg();
          handleProducerCodeValidationMsg();
          handleSubmit(e);
        }
      }}
      data-testid="spectrumSearchFormID"
      className="class_search_container col-md col-lg-7 col-xl-6 mt-3"
    >
      <div className="row">
        <div className="col">
          {/* Need 2 separate components due to HUK version 1.X limitation where hukClass (i.e input type) cannot be dynamically changed.  This is resolved in version 2.X */}
          {selectedOption === "Description" ? (
            <TextInput
              range={range}
              helperText={helperText}
              activeTabID={activeTabID}
              refreshCount={refreshCount}
              selectedOption={selectedOption}
              validationMessage={validationMessage}
            />
          ) : (
            <NumberInput
              helperText={helperText}
              activeTabID={activeTabID}
              refreshCount={refreshCount}
              selectedOption={selectedOption}
              validationMessage={validationMessage}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          {userType === "external" ? (
            <HukAutocomplete
              showallclick
              maxlength="8"
              pattern="\d+$"
              nooptionaltext
              listdisplay="5"
              labelformat="text"
              nohelpertextonerror
              value={producerCode}
              options={workbinProducerCodes}
              refresh={refreshCount}
              helpertextformat="text"
              helpertext="Enter 8 numbers"
              label="Producer code (optional)"
              dataerror={producerCdValidationMsg}
              onBlur={handleProducerCodeValidationMsg}
              showdataerror={showProducerCodeValidation}
              disablevalidation={activeTabID !== "spectrumTabID"}
              onInput={(e) => dispatch(updateProducerCode(e.target.value))}
            />
          ) : (
            <HukInputField
              nopadding
              maxlength="8"
              nooptionaltext
              className="mb-4"
              hukclass="number"
              labelformat="text"
              nohelpertextonerror
              value={producerCode}
              refresh={refreshCount}
              helpertextformat="text"
              helpertext="Enter 8 numbers"
              label="Producer code (optional)"
              data-testid="ProducerCodeInputID"
              onBlur={handleProducerCodeValidationMsg}
              dataerror={producerCdValidationMsg}
              showdataerror={showProducerCodeValidation}
              disablevalidation={activeTabID !== "spectrumTabID"}
              onInput={(e) => dispatch(updateProducerCode(e.target.value))}
            />
          )}
        </div>
        <div className="col-lg-6">
          <HukInputField
            nopadding
            value={cpid}
            maxlength="4"
            nooptionaltext
            gbxunmask={true}
            className="mb-4"
            labelformat="text"
            nohelpertextonerror
            refresh={refreshCount}
            helpertextformat="text"
            label="CPID (optional)"
            data-testid="CPIDInputID"
            helpertext="Enter 4 characters"
            onBlur={handleCPIDValidationMsg}
            dataerror={CPIDValidationMsg}
            showdataerror={showCPIDValidation}
            disablevalidation={activeTabID !== "spectrumTabID"}
            onInput={(e) => dispatch(updateCPID(e.target.value))}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <HukButton
            type="submit"
            text="Search"
            data-testid="search-button"
            data-dl={`{"da_track": "true", "event_id": "Spectrum Search Btn"}`}
            // dynamically hides or shows loading spinner on button depending if all inputs are valid or not
            hukclass={"btn-primary btn-lg btn-block"}
          />
        </div>
      </div>
    </form>
  );
};

export default SpectrumSearch;
