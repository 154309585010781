import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HukSelect, HukCheckbox } from "huk-react-bindings";

import { stateList } from "shared/globalData";
import { requiredFieldErrorMessage, businessSearch } from "shared/messages";
import { updateRiskState } from "redux/actions/riskStateActions";
import { trackCustomEventError } from "tealium/trackCustomEvent";

import "./RiskState.scss";

const RiskState = ({ activeTabID }) => {
  const dispatch = useDispatch();
  const [refreshCount, setRefreshCount] = useState(0);
  const { riskState, showRiskStateValidation } = useSelector(
    (state) => state.riskStateReducer
  );
  const { selectedOption } = useSelector((state) => state.searchByReducer);
  const [rememberState, setRememberState] = useState(
    localStorage.getItem("riskState") ?? false
  );

  /* istanbul ignore next */
  useEffect(() => {
    if (selectedOption === businessSearch) {
      setRefreshCount((refreshCount % 100) + 1);
    } // used to reset validation by using HUK refresh attribute
  }, [selectedOption, activeTabID]);

  /**
   * Checks to see if the risk state is required based on the selected option and active tab
   * @return {boolean} True/False
   */
  const checkRiskStateRequired = () => {
    if (selectedOption === businessSearch && activeTabID === "spectrumTabID") {
      return false;
    }
    return true;
  };

  /**
   * Handler for remember state click and also sets the value forl ocal storage
   * @return {void}
   */
  const handleRememberState = (e) => {
    if (e.detail.value) {
      localStorage.setItem("riskState", riskState);
      setRememberState(true);
    } else {
      localStorage.removeItem("riskState");
      setRememberState(false);
    }
  };

  return (
    <>
      <form className="risk_state_container">
        <HukSelect
          nopadding
          nooptionaltext
          value={riskState}
          labelformat="text"
          gbxunmask={true}
          label="Risk state"
          options={stateList}
          refresh={refreshCount}
          helpertextformat="text"
          optionsmaxheight="15rem"
          dropposition="bottom-left"
          placeholder="Please Select"
          data-dl={`{"event_id": "Risk state drop down", "da_track": "true"}`}
          data-testid="RiskStateDropdownID"
          required={checkRiskStateRequired()}
          datamissing={requiredFieldErrorMessage}
          showdatamissing={showRiskStateValidation}
          requiredasterisk={checkRiskStateRequired()}
          onInput={(e) => {
            dispatch(updateRiskState(e.target.value));
            if (rememberState)
              localStorage.setItem("riskState", e.target.value);
          }}
          onblur={(e) => {
            if (!riskState.trim() && checkRiskStateRequired()) {
              trackCustomEventError(
                "Risk State",
                `${requiredFieldErrorMessage}`,
                "true"
              );
            }
          }}
        />

        <HukCheckbox
          className="mt-3"
          labelformat="text"
          value={rememberState}
          disabled={!riskState}
          data-testid="CheckboxID"
          label="Remember this state"
          data-dl={`{"da_track": "true"}`}
          onHukInput={handleRememberState}
        />
      </form>
    </>
  );
};

export default RiskState;
