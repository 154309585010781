import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HukTableXc, HukIcon } from "huk-react-bindings";

import { pageTag, businessSearch } from "shared/messages";
import { trackCustomEvent } from "tealium/trackCustomEvent";
import { useUpdatedSearchResultsJSON, useClassDetailsLink } from "shared/hooks";

import { AppetiteHelpModal } from "../AppetiteHelpModal/AppetiteHelpModal";

import "./SpectrumDesktopResultsTable.scss";

/**
 * Used to take the description content between the div tags from the resultstable and return just the description text
 * @param {string} descriptionContentDiv the html string from the table which includes the divs
 * @return {string} returns string of the description without divs
 */
export const extractTextFromDiv = (descriptionContentDiv) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(descriptionContentDiv, "text/html");
  const divContent = htmlDoc.body.textContent;
  return divContent;
};

export const SpectrumDesktopResultsTable = ({ searchResultsJSON }) => {
  const [weFoundText, setWeFoundText] = useState("");
  const navigateToClassDetails = useClassDetailsLink();
  const { data } = useSelector((state) => state.searchResultsReducer);
  const { riskState } = useSelector((state) => state.riskStateReducer);
  const { producerCode } = useSelector((state) => state.inputValueReducer);
  const { selectedOption } = useSelector((state) => state.searchByReducer);
  const { businessName, state } = useSelector(
    (state) => state.businessSearchReducer
  );
  const { zipCode } = useSelector((state) => state.businessSearchReducer);
  const { updatedSearchResultsJSON, resultLength } =
    useUpdatedSearchResultsJSON(searchResultsJSON, "Spectrum");
  //use to manipulate huk table styles in the shadow root
  //allows us to set all custom styles for huk table in one place
  useEffect(() => {
    setWeFoundText(`We found ${businessName}`);
    const tableStyleSheet = new CSSStyleSheet();
    const buttonStyleSheet = new CSSStyleSheet();

    tableStyleSheet.replaceSync(
      `.compact { font-size: 0.875rem !important } .icon { font-size: 1.375rem !important }`
    );
    buttonStyleSheet.replaceSync(
      `.btn.compact, .btn.compact .icon { font-size: 0.875rem !important }`
    );

    const hukTableXc =
      window?.document?.querySelector("huk-table-xc")?.shadowRoot;
    if (hukTableXc) {
      const tableAdoptedStyleSheets = hukTableXc.adoptedStyleSheets;
      /* istanbul ignore if */
      if (tableAdoptedStyleSheets) {
        tableAdoptedStyleSheets.push(tableStyleSheet);
      }
    }

    setTimeout(async () => {
      const hukButtons = Array.from(hukTableXc?.querySelectorAll("huk-button"));
      for (let button of hukButtons) {
        const buttonAdoptedStyleSheets = await button.shadowRoot
          .adoptedStyleSheets;
        /* istanbul ignore if */
        if (buttonAdoptedStyleSheets) {
          buttonAdoptedStyleSheets.push(buttonStyleSheet);
        }
      }
    }, 0);
  }, []);

  /**
   * Function to open up the class details page in a new tab when the details button is clicked
   * @param {event} e event object
   * @return {void} returns nothing
   */
  const handleDetailsButtonClick = (e) => {
    /**
     * Below is used for zip code/geography overrides for business search
     * This is for scenarios where user gets a geography override for business search but we don't want that override for spectrum search
     * Ex: business search returns spec code 58011 with geography override, but we want the spectrum search for 58011 without the override
     */
    if (selectedOption === businessSearch) {
      localStorage.setItem("zipCode", zipCode);
      localStorage.setItem("searchType", "Business");
    } else {
      localStorage.setItem("searchType", "Spectrum");
    }

    if (
      e.detail.col === "11" &&
      e.detail.name === "Details" &&
      e.detail.type === "button"
    ) {
      let classCode = "--";
      let lobValue = "";
      const COBId = data[e.detail.index]?.businessClassCaseId || "";
      if (e.detail.row.tableData[1].content !== "--") {
        classCode = e.detail.row.tableData[1].content;
        lobValue = "GL";
      }
      if (e.detail.row.tableData[0].content !== "--") {
        classCode = e.detail.row.tableData[0].content;
        lobValue = "SPEC";
      }
      if (classCode === "--" || lobValue === "") return;

      navigateToClassDetails(
        "Search Page",
        classCode,
        lobValue,
        selectedOption === businessSearch ? state : riskState,
        "SC",
        producerCode,
        COBId
      );

      trackCustomEvent(
        `${pageTag}`,
        "Search Details Button Click",
        `SearchBy_${selectedOption}`,
        `BOP_${e.detail.row.tableData[0].content} | ISOGL_${
          e.detail.row.tableData[1].content
        } | SIC_${e.detail.row.tableData[2].content} | NAICS_${
          e.detail.row.tableData[3].content
        } | Description_${extractTextFromDiv(
          e.detail.row.tableData[4].content
        )}`,
        "true"
      );
    }
  };

  return (
    <div
      data-testid="SpectrumDesktopResultsTableID"
      className="desktop_results_table_container"
    >
      {selectedOption !== businessSearch && (
        <h3 className="header">{resultLength} Results</h3>
      )}
      {selectedOption === businessSearch && (
        <div className="business_search_table_text">
          <div className="business_search_table_header">{weFoundText}</div>
          <div>
            We matched this business to the following class code. If this
            doesn’t match the operations of the business please search by
            description.
          </div>
        </div>
      )}
      {/* row that holds the color code indicators */}
      <div className="color_code_indicators_row row py-2 mx-0 mb-2">
        <div className="col d-flex justify-content-between appetite_spacing">
          <div className="d-flex align-items-center">
            <div className="me-2 pt-2">
              <HukIcon
                icon="bullet-check-mark"
                className="bullet_check_mark_icon"
              />
            </div>
            <div className="fw-medium">In Appetite</div>
          </div>
          <div className="d-flex align-items-center">
            <div className="me-2 pt-2">
              <HukIcon className="attention_alt_icon" icon="attention-alt" />
            </div>
            <div className="fw-medium">Limited Appetite</div>
          </div>
          <div className="d-flex align-items-center">
            <div className="me-2 pt-2">
              <HukIcon
                icon="bullet-not-valid"
                className="bullet_not_valid_icon "
              />
            </div>
            <div className="fw-medium">No Appetite</div>
          </div>
        </div>
        <AppetiteHelpModal />
      </div>
      <div className="results_table">
        <HukTableXc
          tablejson={updatedSearchResultsJSON}
          onHukTableEvent={handleDetailsButtonClick}
        />
      </div>
    </div>
  );
};

export default SpectrumDesktopResultsTable;
