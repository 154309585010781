import UWInfo from "./UWInfo/UWInfo";

import "./ClassDescription.scss";

const ClassDescription = ({ producerCode, searchData }) => {
  const classCode = searchData?.classCode;
  const marketGroupCd = searchData?.marketGroupCd;
  const classCodeType = searchData?.descriptorText;
  const description = searchData?.classCdDefenition;
  const userType = sessionStorage.getItem("userType");

  return (
    <div
      data-testid="ClassCodeID"
      className="class_code_details_container p-0 d-md-flex justify-content-between"
    >
      <div>
        <div className="row">
          <h1 className="class_type_title">{classCodeType}</h1>
        </div>
        <div className="row">
          <p>
            {searchData?.businessClassCdTypeName === "ISO_CGL"
              ? "ISO GL Code"
              : "Spectrum Code"}{" "}
            <span className="class_code">{classCode}</span>
          </p>
        </div>

        {userType === "internal" && (
          <div className="row">
            <p>
              Desc Code{" "}
              <span className="desc_code">{searchData?.descriptorCd}</span>
            </p>
          </div>
        )}

        {producerCode && (
          <div className="row">
            <p>
              Producer Code: <span className="class_code">{producerCode}</span>
            </p>
          </div>
        )}

        <div className="row">
          {searchData?.businessClassCdTypeName === "ISO_CGL" ? (
            ""
          ) : (
            <h2 className="description_title">Description</h2>
          )}
        </div>
        <div className={`row ${userType === "external" ? "pe-md-4" : ""}`}>
          {searchData?.businessClassCdTypeName === "ISO_CGL" ? (
            ""
          ) : (
            <p
              data-testid="DescriptionID"
              className={`${userType === "external" ? "mb-0 mb-sm-3" : ""}`}
            >
              {description}
            </p>
          )}
        </div>
      </div>
      {userType === "external" && (
        <UWInfo
          marketGroupCd={marketGroupCd}
          classCode={searchData.classCode}
          businessClassTypeName={searchData.businessClassCdTypeName}
          producerCode={producerCode}
        />
      )}
    </div>
  );
};

export default ClassDescription;
