import { useDispatch, useSelector } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import { HukInputField, HukSelect, HukButton } from "huk-react-bindings";

import { stateList } from "shared/globalData";
import { callGetSearchResultsAPI } from "shared/helper";
import { axiosPostInstance } from "shared/axiosInstance";
import {
  requiredFieldErrorMessage,
  inputFieldClickdAnalytics,
  pageTag,
} from "shared/messages";
import {} from "tealium/trackCustomEvent";
import { updateDynamicValue } from "redux/actions/inputValueActions";
import { updateRiskStateValidation } from "redux/actions/riskStateActions";
import {
  trackCustomEvent,
  trackCustomEventError,
} from "tealium/trackCustomEvent";
import {
  updateBusinessName,
  updateDBA,
  updateStreet,
  updateCity,
  updateState,
  updateZip,
  updateConfidenceScoreThresholdReached,
} from "redux/actions/businessSearchActions";
import {
  getAddressCleansedURL,
  getSmartClassURL,
  getEnhancedAppetiteURL,
} from "shared/apiEndpoints";
import { searchStart, searchFailure } from "redux/actions/searchResultsActions";

const BusinessSearch = ({ activeTabID }) => {
  const dispatch = useDispatch();
  const userType = sessionStorage.getItem("userType");
  const [refreshCount, setRefreshCount] = useState(0);
  const { searchType } = useSelector((state) => state.searchByReducer);
  const { selectedOption } = useSelector((state) => state.searchByReducer);
  const { businessName, dbaName, street, city, state, zipCode } = useSelector(
    (state) => state.businessSearchReducer
  );
  // below useStates are for setting validation message in relation to enter btn press for submit
  const [showBusinessNameValidation, setShowBusinessNameValidation] =
    useState(false);
  const [showStreetAddressValidation, setShowStreetAddressValidation] =
    useState(false);
  const [showCityValidation, setShowCityValidation] = useState(false);
  const [showStateValidation, setShowStateValidation] = useState(false);
  const [showZipCodeValidation, setShowZipCodeValidation] = useState(false);

  /* istanbul ignore next */
  useEffect(() => {
    // reset all inputs when going to a different tab so on return the inputs are empty
    if (activeTabID !== "spectrumTabID") {
      dispatch(updateDBA(""));
      dispatch(updateZip(""));
      dispatch(updateCity(""));
      dispatch(updateState("Please Select"));
      dispatch(updateStreet(""));
      dispatch(updateBusinessName(""));
    }

    /*
      This dispatch has to stay out of if statement because we need the dynamic input to reset whenever going out of SpectrumSearch component
      so when returning the input will be empty
    */
    dispatch(updateDynamicValue(""));
    dispatch(updateRiskStateValidation(false));
    setRefreshCount((refreshCount % 100) + 1); // used to reset validation by using HUK refresh attribute
  }, [activeTabID]);

  /**
   * Handler for search button click along with displaying validation errors (if applicable) on enter btn press
   * @return {boolean}
   */
  const checkAllFieldsValid = () => {
    let allFieldsValid = true;

    if (!businessName.trim()) {
      setShowBusinessNameValidation(true);
      allFieldsValid = false;
    } else {
      setShowBusinessNameValidation(false);
    }

    if (!street.trim()) {
      setShowStreetAddressValidation(true);
      allFieldsValid = false;
    } else {
      setShowStreetAddressValidation(false);
    }

    if (!city.trim()) {
      setShowCityValidation(true);
      allFieldsValid = false;
    } else {
      setShowCityValidation(false);
    }

    if (!state.trim()) {
      setShowStateValidation(true);
      allFieldsValid = false;
    } else {
      setShowStateValidation(false);
    }

    if (!zipCode.trim()) {
      setShowZipCodeValidation(true);
      allFieldsValid = false;
    } else {
      setShowZipCodeValidation(false);
    }

    return allFieldsValid;
  };

  /**
   * sets the validation message for producer code via useState when input is blurred
   */
  const handleValidationErrorAnalyticsOnSubmit = () => {
    if (!businessName.trim()) {
      trackCustomEventError("Business Name", requiredFieldErrorMessage, "true");
    }

    if (!street.trim()) {
      trackCustomEventError(
        "Street address",
        requiredFieldErrorMessage,
        "true"
      );
    }

    if (!city.trim()) {
      trackCustomEventError("City", requiredFieldErrorMessage, "true");
    }

    if (!state.trim()) {
      trackCustomEventError(
        "Business Search Risk State",
        requiredFieldErrorMessage,
        "true"
      );
    }

    if (!zipCode.trim()) {
      trackCustomEventError("Zip code", requiredFieldErrorMessage, "true");
    }
  };

  const handleSubmit = (e) => {
    /* istanbul ignore next */
    e.preventDefault();

    if (!checkAllFieldsValid()) {
      handleValidationErrorAnalyticsOnSubmit();
      return;
    }
    dispatch(updateConfidenceScoreThresholdReached(true));

    const businessSearchPayload = {
      businessName,
      businessNameLine2: "",
      dbaName,
      insuredStreet: street,
      insuredCity: city,
      insuredState: state,
      insuredZip: zipCode,
    };

    dispatch(searchStart());

    /* istanbul ignore next */
    axiosPostInstance()
      .post(getAddressCleansedURL, businessSearchPayload)
      .then((res) => {
        const data = res.data;
        console.log("Address Cleanse Response: ", data);

        // if address cleanse returns due to bad request or not found, show 'no results found' error message
        if (data.statusCode === 400 || data.statusCode === 404) {
          /*
            Dispatch empty action b/c this will be sent as 'undefined' which is a 'falsey' value
            and therefore will trigger the 'no results found' logic block instead of the error logic block
          */
          dispatch(searchFailure());
          return;
        }

        // ONLY call smartClass API and getSearchResults API if addressCleanse returns 0, otherwise do NOT call the next 2 APIs and show the 'No Business Match Found' error message
        if (data.addresses[0].addressCleanse === "0") {
          axiosPostInstance()
            .post(getSmartClassURL, businessSearchPayload)
            .then((res) => {
              const data = res.data;
              const smartClassCode = data.responseData.classCode.spectrumCode;
              const confidenceScore = data.responseData.classCode.confidence;
              console.log("Smart Class Response: ", data);

              if (confidenceScore > 0.5) {
                dispatch(updateConfidenceScoreThresholdReached(true));

                const enhancedAppetitePayload = {
                  spectrumCode: smartClassCode,
                  businessName,
                  businessNameLine2: "",
                  dbaName,
                  insuredStreet: street,
                  insuredCity: city,
                  insuredState: state,
                  insuredZip: zipCode,
                };

                // Call  Appetite API if confidence score is greater than 50%
                axiosPostInstance()
                  .post(getEnhancedAppetiteURL, enhancedAppetitePayload)
                  .then((res) => {
                    const data = res.data;
                    console.log("Enhanced Appetite Response: ", data);

                    // Prepare payload for getSearchResultsAPI
                    const getSearchResultsPayload = {
                      lobcd: "SPEC",
                      stateCd: state,
                      segmentCd: "SC",
                      searchId: data?.data[0]?.spectrumClassCode,
                      sentinelIndicator: "Y",
                      searchType,
                      agentViewIndicator: userType === "internal" ? "N" : "Y",
                      zipCode: zipCode,
                    };

                    // Call getSearchResultsAPI
                    callGetSearchResultsAPI(dispatch, getSearchResultsPayload);
                  })
                  .catch((Err) => {
                    console.log(" Appetite API Error:", Err);
                    dispatch(searchFailure(Err.message));
                  });
              } else {
                dispatch(updateConfidenceScoreThresholdReached(false));
                dispatch(searchFailure());
                return;
              }
            })
            .catch((err) => {
              console.log(err);
              dispatch(searchFailure(err.message));
            });
        } else {
          dispatch(searchFailure());
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(searchFailure(err));
      });

    trackCustomEvent(
      `${pageTag}`,
      "Search Button Click",
      `SearchBy_${selectedOption}`,
      `BusinessName_${businessName} | DBA_${dbaName} | StreetAddress_${street} | City_${city} | State_${state} | ZipCode_${zipCode}`,
      "true"
    );
  };

  return (
    <form
      className="col mt-3 mt-md-0"
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit(e);
        }
      }}
      data-testid="FormID"
    >
      <div className="row">
        <div className="col">
          <HukInputField
            required
            maxlength="45"
            nooptionaltext
            requiredasterisk
            gbxunmask={true}
            labelformat="text"
            value={businessName}
            label="Business name"
            refresh={refreshCount}
            helpertextformat="text"
            dataerror={requiredFieldErrorMessage}
            data-testid="BusinessID"
            datamissing={requiredFieldErrorMessage}
            showdatamissing={showBusinessNameValidation}
            onInput={(e) => dispatch(updateBusinessName(e.target.value))}
            disablevalidation={activeTabID !== "spectrumTabID"}
            onblur={(e) => {
              /*
                current version of HUK does not support onValid the way we need it to
                in order to track analytics for these inputs we need to utilize blur event and confirm if field is empty or not
              */
              if (!businessName.trim()) {
                trackCustomEventError(
                  "Business Name",
                  requiredFieldErrorMessage,
                  "true"
                );
              } else {
                trackCustomEvent(
                  `${pageTag}`,
                  `${inputFieldClickdAnalytics}`,
                  `Business Name`,
                  `${businessName}`,
                  "true"
                );
                setShowBusinessNameValidation(false);
              }
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <HukInputField
            maxlength="45"
            nooptionaltext
            gbxunmask={true}
            value={dbaName}
            labelformat="text"
            data-testid="DBAID"
            helpertextformat="text"
            label="DBA (optional)"
            onInput={(e) => dispatch(updateDBA(e.target.value))}
            onblur={(e) => {
              trackCustomEvent(
                `${pageTag}`,
                `${inputFieldClickdAnalytics}`,
                `DBA`,
                `${dbaName}`,
                "true"
              );
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <HukInputField
            required
            value={street}
            maxlength="30"
            nooptionaltext
            gbxunmask={true}
            requiredasterisk
            labelformat="text"
            refresh={refreshCount}
            data-testid="StreetID"
            label="Street address"
            helpertextformat="text"
            dataerror={requiredFieldErrorMessage}
            datamissing={requiredFieldErrorMessage}
            showdatamissing={showStreetAddressValidation}
            onInput={(e) => dispatch(updateStreet(e.target.value))}
            disablevalidation={activeTabID !== "spectrumTabID"}
            onblur={(e) => {
              if (!street.trim()) {
                trackCustomEventError(
                  "Street address",
                  requiredFieldErrorMessage,
                  "true"
                );
              } else {
                trackCustomEvent(
                  `${pageTag}`,
                  `${inputFieldClickdAnalytics}`,
                  `street address`,
                  `${street}`,
                  "true"
                );
                setShowStreetAddressValidation(false);
              }
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <HukInputField
            required
            value={city}
            label="City"
            maxlength="15"
            nooptionaltext
            gbxunmask={true}
            requiredasterisk
            labelformat="text"
            data-testid="cityID"
            refresh={refreshCount}
            helpertextformat="text"
            showdatamissing={showCityValidation}
            dataerror={requiredFieldErrorMessage}
            datamissing={requiredFieldErrorMessage}
            onInput={(e) => dispatch(updateCity(e.target.value))}
            disablevalidation={activeTabID !== "spectrumTabID"}
            onblur={(e) => {
              if (!city.trim()) {
                trackCustomEventError(
                  "City",
                  requiredFieldErrorMessage,
                  "true"
                );
              } else {
                trackCustomEvent(
                  `${pageTag}`,
                  `${inputFieldClickdAnalytics}`,
                  `City`,
                  `${city}`,
                  "true"
                );
                setShowCityValidation(false);
              }
            }}
          />
        </div>

        <div className="col-lg-4">
          <HukSelect
            value={state}
            label="State"
            size="medium"
            requiredasterisk
            gbxunmask={true}
            labelformat="text"
            options={stateList}
            refresh={refreshCount}
            data-testid="statesID"
            helpertextformat="text"
            optionsmaxheight="11rem"
            dataerror={requiredFieldErrorMessage}
            dropposition="bottom-left"
            datamissing={requiredFieldErrorMessage}
            showdatamissing={showStateValidation}
            placeholder="Please Select"
            onInput={(e) => dispatch(updateState(e.target.value))}
            required={activeTabID === "spectrumTabID"}
            onblur={(e) => {
              if (!state.trim()) {
                trackCustomEventError(
                  "Business Search Risk State",
                  requiredFieldErrorMessage,
                  "true"
                );
              } else {
                setShowStateValidation(false);
              }
            }}
          />
        </div>

        <div className="col-lg-3">
          <HukInputField
            required
            maxlength="10"
            nooptionaltext
            gbxunmask={true}
            value={zipCode}
            requiredasterisk
            label="Zip code"
            hukclass="number"
            labelformat="text"
            refresh={refreshCount}
            data-testid="zipcodeID"
            helpertextformat="text"
            dataerror={requiredFieldErrorMessage}
            datamissing={requiredFieldErrorMessage}
            showdatamissing={showZipCodeValidation}
            onInput={(e) => dispatch(updateZip(e.target.value))}
            disablevalidation={activeTabID !== "spectrumTabID"}
            onblur={(e) => {
              if (!zipCode.trim()) {
                trackCustomEventError(
                  "Zip code",
                  requiredFieldErrorMessage,
                  "true"
                );
              } else {
                trackCustomEvent(
                  `${pageTag}`,
                  `${inputFieldClickdAnalytics}`,
                  `Zip code`,
                  `${zipCode}`,
                  "true"
                );
                setShowZipCodeValidation(false);
              }
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <HukButton
            type="submit"
            text="Search"
            className="search_button"
            data-testid="search-button"
            data-dl={`{"da_track": "true", "event_id": "Spectrum Search Btn"}`}
            // dynamically hides or shows loading spinner on button depending if all inputs are valid or not
            hukclass={"btn-primary btn-md btn-block"}
          />
        </div>
      </div>
    </form>
  );
};

export default BusinessSearch;
