import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HukIcon, HukButton } from "huk-react-bindings";

import { businessSearch } from "shared/messages";
import { useClassDetailsLink } from "shared/hooks";
import { AppetiteHelpModal } from "../AppetiteHelpModal/AppetiteHelpModal";
import { extractTextFromDiv } from "../Desktop/SpectrumDesktopResultsTable";

import "./SpectrumMobileResultsTable.scss";

export const SpectrumMobileResultsTable = ({ searchResultsJSON }) => {
  const [weFoundText, setWeFoundText] = useState("");
  const navigateToClassDetails = useClassDetailsLink();
  const { data } = useSelector((state) => state.searchResultsReducer);
  const { riskState } = useSelector((state) => state.riskStateReducer);
  const { producerCode } = useSelector((state) => state.inputValueReducer);
  const { selectedOption } = useSelector((state) => state.searchByReducer);
  const { zipCode } = useSelector((state) => state.businessSearchReducer);
  const { businessName, state } = useSelector(
    (state) => state.businessSearchReducer
  );

  useEffect(() => {
    setWeFoundText(`We found ${businessName}`);
  }, []);

  /**
   * Creates an array of objects that contain the description and the LOB indicators for the search results
   * @return {array} - Objects containing the search results description and appetite indicator icons
   */
  const createResultsTableData = () =>
    searchResultsJSON.tableRows.map((row, rowIndex) => {
      const reg = />(.*?)<\/div>/;
      const textMatch = row.tableData[4].content.match(reg);
      const classTitleText = textMatch ? textMatch[1] : "";

      let classCode = "--";
      let lobValue = "";
      const COBId = data[rowIndex]?.businessClassCaseId || "";
      if (row.tableData[1].content !== "--") {
        classCode = row.tableData[1].content;
        lobValue = "GL";
      }
      if (row.tableData[0].content !== "--") {
        classCode = row.tableData[0].content;
        lobValue = "SPEC";
      }

      return {
        description: classTitleText,
        classCode: classCode,
        lobValue: lobValue,
        COBId: COBId,
        lobCodes: {
          bopCode: row.tableData[0].content,
          glCode: row.tableData[1].content,
          sicCode: row.tableData[2].content,
          naicsCode: row.tableData[3].content,
          codeDescription: extractTextFromDiv(row.tableData[4].content),
        },
        lob: row.tableData.slice(5, 10).map((cell, cellIndex) => {
          const iconMatch = cell.content.match(/icon='(.*?)'/);
          let icon;
          if (iconMatch == null) {
            icon = "--";
          } else if (iconMatch[1] === "icon icon-stop") {
            icon = "stop";
          } else {
            icon = iconMatch[1];
          }

          return {
            lob: searchResultsJSON.tableHeadings[cellIndex + 5].heading,
            icon: icon,
          };
        }),
      };
    });

  /**
   * Creates a single row for the search results data
   * @return {JSX} JSX containing the description, appetite icon and details button
   */
  const createResultsDataRows = () =>
    createResultsTableData().map((result) => {
      return (
        <div key={result.description}>
          <div className="row mb-2 fw-medium">
            <div className="description_header">Description</div>
            <div>{result.description}</div>
          </div>
          <div className="row align-items-end fw-medium">
            {result.lob.map((lob) => {
              return (
                <div key={lob.lob} className="col">
                  {lob.lob}
                </div>
              );
            })}
          </div>
          <div className="row my-4 fw-medium">
            {result.lob.map((lob) => {
              return (
                <div key={lob.lob} className="col">
                  {lob.icon !== "--" ? (
                    <HukIcon
                      iconsize="2x"
                      icon={lob.icon}
                      className={lob.icon}
                    />
                  ) : (
                    "--"
                  )}
                </div>
              );
            })}
          </div>
          <div className="row mt-4">
            <HukButton
              type="button"
              text="Details"
              hukclass="btn-secondary btn-lg"
              iconclass="icon icon-caret-right"
              data-dl={`{"da_track": "true", "event_id": "SearchBy_${selectedOption}", "event_value": "BOP_${result.lobCodes.bopCode} | ISOGL_${result.lobCodes.glCode} | SIC_${result.lobCodes.sicCode} | NAICS_${result.lobCodes.naicsCode} | Description_${result.lobCodes.codeDescription}", "event_type": "Search Details Button Click"}`}
              onHukClick={(e) => {
                // this mirrors spectrum desktop results table.  See that component for more details
                if (selectedOption === businessSearch) {
                  localStorage.setItem("zipCode", zipCode);
                  localStorage.setItem("searchType", "Business");
                } else {
                  localStorage.setItem("searchType", "Spectrum");
                }

                if (result.classCode === "--" || result.lobValue === "") return;
                navigateToClassDetails(
                  "Search Page",
                  result.classCode,
                  result.lobValue,
                  selectedOption === businessSearch ? state : riskState,
                  "SC",
                  producerCode,
                  result.COBId
                );
              }}
            />
          </div>
          <hr className="mt-5" />
        </div>
      );
    });

  const resultLength = searchResultsJSON.tableRows.length;

  return (
    <div
      data-testid="SpectrumMobileResultsTableID"
      className="mobile_results_table_container container"
    >
      {selectedOption !== businessSearch && (
        <h2 className="header">{resultLength} Results</h2>
      )}
      {selectedOption === businessSearch && (
        <div className="business_search_table_text">
          <div className="business_search_table_header">{weFoundText}</div>
          <div>
            We matched this business to the following class code. If this
            doesn’t match the operations of the business please search by
            description.
          </div>
        </div>
      )}

      {/* row that holds the color code indicators */}
      <div className="color_code_indicators_row row py-3 px-4 mx-0">
        <div className="d-flex align-items-center">
          <div className="me-3 pt-2">
            <HukIcon
              iconsize="2x"
              icon="bullet-check-mark"
              className="bullet-check-mark"
            />
          </div>
          <div className="fw-medium">In Appetite</div>
        </div>
        <div className="d-flex align-items-center">
          <div className="me-3 pt-2">
            <HukIcon
              iconsize="2x"
              icon="attention-alt"
              className="attention-alt"
            />
          </div>
          <div className="fw-medium">Limited Appetite</div>
        </div>
        <div className="d-flex align-items-center">
          <div className="me-3 pt-2">
            <HukIcon
              iconsize="2x"
              icon="bullet-not-valid"
              className="bullet-not-valid"
            />
          </div>
          <div className="fw-medium">No Appetite</div>
        </div>
        <AppetiteHelpModal mobileView />
      </div>

      <hr className="my-4" />

      {/* Below holds the result description and LOB indicators */}
      <div className="specific_Result">
        <div>{createResultsDataRows()}</div>
      </div>
    </div>
  );
};

export default SpectrumMobileResultsTable;
