export const somethingWentWrong = "Something went wrong";

export const somethingWentWrongDescription =
  "We're sorry and we're working to fix it. Please try again later";

export const noResultsFound = "No Results Found";

export const returnToSearch = "Return to Search";

export const closeAndReturnToIcon = "Close and Return to ICON";

export const noResultsFoundDescription =
  "Your search didn't return any results. Try another search instead";

export const requiredFieldErrorMessage = "This is a required field";

export const noClassInformationFound = "No Class Information Found";

export const noClassInformationFoundDescription =
  "We found the business but were unable to determine its class. Try another search";

export const noBusinessMatchFound = "No Business Match Found";

export const businessSearch = "Business search";

// below are analytics specific const/text
export const inputFieldClickdAnalytics = "Input Field Click - (text)";

export const pageTag = "Page Tag";
